import React, { useEffect, useRef, useState } from 'react';
import { AudiMarketContextServiceV2 } from '@oneaudi/market-context-service';
import styled from 'styled-components';
import { Loader } from '@audi/audi-ui-react';

export interface LayerContentHTMLProps {
  audiMarketContextService?: AudiMarketContextServiceV2;
  url: string;
  withFefa?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  fromLayer?: boolean;
  fefaStyleConfig?: IFefaStyleProps;
  enableDeepLinking?: boolean;
}

interface IFefaStyleProps {
  omitBottomSpacing?: string;
  omitSideSpacing?: string;
  uiTheme?: string;
}

export const MARKER_XHR = 'headless';
declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    SETUPS: any;
  }
}

const LoadingWrapper = styled.div`
  text-align: center;
  div {
    color: var(${({ theme }): string => theme.colors.ui.primary});
  }
`;

export const LayerContentHTML: React.FC<LayerContentHTMLProps> = ({
  audiMarketContextService,
  url,
  withFefa = false,
  fefaStyleConfig,
  enableDeepLinking = false
}) => {
  const [html, setHtml] = useState<string | null>(null);
  const [fetchError, setFetchError] = useState<Error | null>(null);
  const layerElementRef = useRef<HTMLDivElement>(null);
  const htmlContentElementRef = useRef<HTMLDivElement>(null);
  const [randomNumber] = useState(Math.floor(Math.random() * (1000 - 1) + 1));
  const layerElementClassName = `one-layer-headless-${randomNumber}`;
  const [fefaFeatureAppUrl, setFefaFeatureAppUrl] = useState<string | null>(null);
  const [oneFootnoteJsonUrl, setOneFootnoteJsonUrl] = useState<string | null>(null);

  useEffect(() => {
    /* istanbul ignore next */
    if (typeof window !== 'undefined' && window.SETUPS) {
      // TODO this part need to be replace with env config service in order to work properly
      const appVersionUrl = window.SETUPS.get('nemo.FefaFeatureAppVersion');
      const footnotesUrl = window.SETUPS.get('nemo.fefa.oneFootnoteJsonUrl');
      if (appVersionUrl) {
        setFefaFeatureAppUrl(appVersionUrl);
      }
      if (footnotesUrl) {
        setOneFootnoteJsonUrl(footnotesUrl);
      }
    }
  }, []);
  const sanitizeUrlInput = (urlToSanitize: string) => {
    // Remove leading and trailing whitespace
    let sanitizedUrl = urlToSanitize.trim();

    // Remove any potentially dangerous characters
    sanitizedUrl = sanitizedUrl.replace(/[^\w\s.:/-]/g, '');

    // Escape special characters
    sanitizedUrl = encodeURI(sanitizedUrl);

    return sanitizedUrl;
  };

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        let url_ = url;

        if (typeof audiMarketContextService !== 'undefined') {
          try {
            await audiMarketContextService.initMarketContext();
            const cacheKiller = audiMarketContextService.getContextItem('CacheKiller') as string;
            url_ = rewriteUrl(url_, cacheKiller);
          } catch (error) {
            // eslint-disable-next-line no-console
            console.warn(error);
          }
        }
        url_ = sanitizeUrlInput(url_);
        const response = await fetch(url_);
        const data = await response.text();

        setHtml(data);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
      } catch (error) {
        setFetchError(new Error('Error fetching Market Context Service'));
      }
    })();
  }, [audiMarketContextService, url]);

  useEffect(() => {
    if (
      !document ||
      layerElementRef.current === null ||
      htmlContentElementRef.current === null ||
      html === null
    ) {
      return;
    }

    // eslint-disable-next-line max-len
    // TODO we need to strip away any potential feature app include of one-layer since the layer cannot be nested in itself

    // React's dangerouslySetInnerHTML uses innerHTML, which prevents scripts
    // from being executed. We explicitly want to support this though. So
    // instead, we are creating a document fragment from the fetched HTML, and
    // appending that to the HTML content div. Sources:
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/innerHTML
    // https://www.npmjs.com/package/dangerously-set-html-content
    htmlContentElementRef.current.appendChild(
      document.createRange().createContextualFragment(html)
    );

    const contentRendered = new CustomEvent('content:rendered', {
      detail: { element: layerElementRef.current }
    });
    const oneLayerLoaded = new CustomEvent('ONELAYER_LOADED', {
      detail: { element: layerElementRef.current }
    });

    document.dispatchEvent(contentRendered);
    document.dispatchEvent(oneLayerLoaded);
  }, [html]);

  useEffect(() => {
    const filteredUrl = filterUrl(url);

    if (enableDeepLinking) {
      const newParams = {
        oneLayer: filteredUrl
      };
      window.history.pushState(newParams, '', `#one-layer=${filteredUrl}`);
    }
  }, [url]);

  if (fetchError !== null) {
    return <p>{fetchError instanceof Error ? `Error: - ${fetchError?.message}` : fetchError}</p>;
  }

  if (html === null) {
    return (
      <LoadingWrapper>
        <Loader inverted size="small" monochrome spaceStackStart="xl" label="Loading" />
      </LoadingWrapper>
    );
  }

  return (
    <div className={layerElementClassName} ref={layerElementRef}>
      <div ref={htmlContentElementRef} />
      {withFefa && oneFootnoteJsonUrl && fefaFeatureAppUrl && (
        <FEFA
          fefaFeatureAppUrl={fefaFeatureAppUrl}
          fefaStyleConfig={fefaStyleConfig}
          layerElementClassName={layerElementClassName}
          oneFootnoteJsonUrl={oneFootnoteJsonUrl}
          randomNumber={randomNumber}
        />
      )}
    </div>
  );
};

export const FEFA: React.FC<{
  fefaFeatureAppUrl: string;
  oneFootnoteJsonUrl: string;
  randomNumber: number;
  layerElementClassName: string;
  fefaStyleConfig?: IFefaStyleProps;
}> = ({
  fefaFeatureAppUrl,
  oneFootnoteJsonUrl,
  randomNumber,
  layerElementClassName,
  fefaStyleConfig
}) => {
  return (
    <>
      {/*
        // eslint-ignore-nextline
        // @ts-expect-error feature app type  doesnt exist  */}
      <feature-app
        config={JSON.stringify({
          apiUrls: [oneFootnoteJsonUrl],
          disclaimerManagerScope: `one-layer-headless-${randomNumber}`,
          layerElementClassName,
          ...fefaStyleConfig
        })}
        id={`audi-footnote-engine-headless-one-layer-${randomNumber}`}
        src={fefaFeatureAppUrl}
      >
        {/*
        // eslint-ignore-nextline
        // @ts-expect-error feature app type doesnt exist */}
      </feature-app>
    </>
  );
};

/**
 * Adds `cacheKiller` string between document name and extension
 *
 * @example
 * rewriteUrl('test.html', 'nocache')
 * // => test.nocache.headless.html
 */
export const rewriteUrl: (url: string, cacheKiller: string) => string = (url, cacheKiller) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const ext = url.split('#')[0].split('?')[0].split('.').pop()!;
    return url.split(ext).join(`${cacheKiller}.${MARKER_XHR}.${ext}`);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn(error);
    return url;
  }
};

export const filterUrl: (url: string) => string = (url) => {
  const nonNecessaryElement = ['http:', 'https:', 'https://', '', 'www'];

  return url
    .split('/')
    .filter((path) => !nonNecessaryElement.includes(path))
    .join('/');
};
