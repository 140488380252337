import { HistoryServiceV3 } from '@feature-hub/history-service';

export const clearOneLayerQuery = (layerLength: number, historyService: HistoryServiceV3) => {
  const hasDeepLink =
    window.location.hash.includes('one-layer=') || window.location.hash.includes('oneLayer=');
  // eslint-disable-next-line no-restricted-globals
  if (hasDeepLink) {
    historyService?.history.go(-layerLength);
    const currentUrl = new URL(window.location.href);

    // Parse the existing hash parameters
    const hashParams = new URLSearchParams(window.location.hash.slice(1));

    // Remove the 'one-layer' parameter
    if (hashParams.has('one-layer')) {
      hashParams.delete('one-layer');
    } else if (hashParams.has('oneLayer')) {
      hashParams.delete('oneLayer');
    }

    const searchParams = new URLSearchParams(currentUrl.search);
    // Check if 'withFefa' exists before deleting
    if (searchParams.has('withFefa')) {
      // Handle search parameters
      const div = document.querySelector('#layer-content');
      div?.setAttribute('data-fefa-layer-active', 'false');
      searchParams.delete('withFefa');
      currentUrl.search = searchParams.toString();
    }

    // Reassign the updated hash back to the URL object
    currentUrl.hash = hashParams.toString();
    // Update the browser history without reloading
    historyService.rootHistory.replace(currentUrl.toString(), {});
  }
};
