/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import {
  DisclaimerLayerDescriptionV2,
  FocusLayerSizeV2,
  LayerDescriptionV2
} from '@volkswagen-onehub/layer-manager';
import { FeatureAppLoader } from '@feature-hub/react';
import { Loader } from '@audi/audi-ui-react';
import styled from 'styled-components';
import { Spawn } from '@oneaudi/falcon-tools';
import { ALERT, FOCUS, FOCUS_LARGE, FOCUS_SMALL, FULLSCREEN } from './globals';
import { OneLayer } from './one-layer';
import { clearOneLayerQuery } from './util';
import { AppContext } from '../context/app';

const LoadingWrapper = styled.div`
  text-align: center;
  div {
    color: var(${({ theme }): string => theme.colors.ui.primary});
  }
`;
const loader = (
  <LoadingWrapper>
    <Loader inverted size="small" monochrome spaceStackStart="xl" label="Loading" />
  </LoadingWrapper>
);
interface IOneLayerManager {
  layerDescription: LayerDescriptionV2 | DisclaimerLayerDescriptionV2;

  active: boolean;
  zIndexAlert: number;
  zIndex?: number;
  state?: unknown;
  layerLength: number;
  setStartHideAnimation: (state: boolean) => void;
  startHideAnimation: boolean;
  scrollOffset?: number;
  setScrollOffset: (id: string, n: number) => void;
  layerHistory: number[];
  index: number;
}

const OneLayerManager: React.FC<IOneLayerManager> = (props) => {
  const {
    layerDescription,
    active,
    zIndexAlert,
    zIndex,
    layerLength,
    setStartHideAnimation,
    startHideAnimation,
    setScrollOffset,
    layerHistory,
    scrollOffset,
    index
  } = props;
  // @ts-ignore
  const { close, options, id, state } = layerDescription.layer;
  const { historyService, isHeadless, fefaFeatureAppUrl, oneFootnoteUrls, contentApiConfig } =
    React.useContext(AppContext);
  const [randomNumber] = useState(Math.floor(Math.random() * (1000 - 1) + 1));
  const footNoteEngineId = `fa-footnote-engine-${randomNumber}`;
  const layerElementClassName = `one-layer-headless-${randomNumber}`;
  const idPrefix = `layer-content-${randomNumber}`;
  const userCloseable = options?.userCloseable !== false;

  const type = useMemo(() => {
    if (layerDescription.type === ALERT) {
      return ALERT;
    }
    if (layerDescription.type === FULLSCREEN) {
      return FULLSCREEN;
    }
    if (layerDescription.type === FOCUS) {
      if (layerDescription.layer.options.size === FocusLayerSizeV2.A) {
        return FOCUS_LARGE;
      }
      return FOCUS_SMALL;
    }
    return undefined;
  }, [layerDescription]);

  const showCloseButton = useMemo(() => {
    if (type === ALERT) {
      return false;
    }
    return options.userCloseable;
  }, [options, type]);

  useEffect(() => {
    if (startHideAnimation && layerLength === 1) {
      // CASE: close one layer immediately
      close();
      setStartHideAnimation(false);
    }
  }, [close, layerLength, setStartHideAnimation, startHideAnimation]);

  const lastHistoryElement = layerHistory[layerHistory.length - 1];

  const showAnimation = useMemo(() => {
    return (
      layerLength > 1 &&
      ((startHideAnimation && !active) || (!startHideAnimation && active)) &&
      lastHistoryElement - index < 3
    );
  }, [active, layerLength, startHideAnimation]);

  const invisible = useMemo(() => {
    return layerLength > 1 && lastHistoryElement - index > 0 && !showAnimation;
  }, [lastHistoryElement, index, layerLength, showAnimation]);

  const hideAnimation = useMemo(
    () =>
      layerLength > 1 &&
      ((startHideAnimation && active) || (!startHideAnimation && !active)) &&
      lastHistoryElement - index < 3,
    [active, layerLength, startHideAnimation]
  );

  const openAnimation = useMemo(() => {
    const isOpen =
      layerLength === 1 &&
      (layerHistory.length === 1 || (lastHistoryElement === 1 && layerHistory.length === 2));

    return isOpen && !showAnimation && !hideAnimation;
  }, [hideAnimation, showAnimation, layerLength, layerHistory, lastHistoryElement]);

  useEffect(() => {
    const listener = (e: KeyboardEvent): void => {
      if ((!userCloseable && userCloseable !== undefined) || type === ALERT) {
        return;
      }

      if (active && e.key === 'Escape') {
        e.stopPropagation();
        clearOneLayerQuery(layerLength, historyService);
        setStartHideAnimation(true);
      }
    };
    /* istanbul ignore next */
    document?.addEventListener('keydown', listener, false);
    return (): void => {
      /* istanbul ignore next */
      document?.removeEventListener('keydown', listener, false);
    };
  }, [active, setStartHideAnimation]);

  // Only re-render the layer content if the layer itself is changed or the state is changed
  const layerContent = useMemo(
    () => layerDescription.layer.render(),
    // @ts-ignore
    [layerDescription.layer, layerDescription.layer.state]
  );
  let url: string | null = '';
  // @ts-ignore
  // eslint-disable-next-line no-unsafe-optional-chaining
  url = layerContent?.props?.url;
  // @ts-ignore
  const enableDeepLinking = layerContent?.props?.enableDeepLinking;
  // @ts-ignore
  const withFefa = layerContent?.props?.withFefa;
  // @ts-ignore
  const fromLayer = layerContent?.props?.fromLayer;

  useEffect(() => {
    if (url && isHeadless) {
      if (enableDeepLinking) {
        const newParams = {
          oneLayer: url
        };
        const currentUrl = new URL(window.location.href);
        if (withFefa) {
          const div = document.querySelector('#layer-content');
          div?.setAttribute('data-fefa-layer-active', 'true');
        }
        // only adapt URL is provided in external component
        if (!fromLayer) {
          // Decode the provided URL parameter to use actual slashes
          const decodedUrl = decodeURIComponent(url);

          // Construct the hash with `one-layer`
          const hashParams = new URLSearchParams();
          if (withFefa) {
            currentUrl.searchParams.append('withFefa', 'true');
          }

          hashParams.set('one-layer', decodedUrl!);

          // Manually construct the hash string to avoid encoding
          const updatedHash = Array.from(hashParams.entries()).map(
            ([key, value]) => `${key}=${value}`
          );

          // Update the hash with the manually constructed string
          currentUrl.hash = updatedHash.toString();
          historyService.rootHistory.replace(currentUrl.toString(), newParams);
        } else {
          // eslint-disable-next-line no-restricted-globals
          historyService.rootHistory.replace(location.href, { id: '' });
        }
      }
    }
  }, [url]);

  if (!type) {
    return null;
  }

  return (
    <OneLayer
      active={active}
      close={close}
      hideAnimation={hideAnimation}
      id={id}
      index={index}
      openAnimation={openAnimation}
      scrollOffset={scrollOffset}
      setScrollOffset={setScrollOffset}
      setStartHideAnimation={setStartHideAnimation}
      showAnimation={showAnimation}
      showCloseButton={showCloseButton}
      type={type}
      zIndex={zIndex}
      zIndexAlert={zIndexAlert}
      state={state}
      layerLength={layerLength}
      userCloseable={userCloseable}
      invisible={invisible}
    >
      {isHeadless && url ? (
        <div
          id="layer-content"
          className={layerElementClassName}
          data-fefa-custom-id={footNoteEngineId}
        >
          <Spawn
            featureApps={url.trim()}
            idPrefix={idPrefix}
            contentApiConfig={contentApiConfig}
            loader={loader}
            data-fefa-custom-id={footNoteEngineId}
          />
          {withFefa && fefaFeatureAppUrl && oneFootnoteUrls && (
            <Suspense fallback={loader}>
              <LazyFefa
                layerElementClassName={layerElementClassName}
                oneFootnoteUrls={oneFootnoteUrls}
                fefaFeatureAppUrl={fefaFeatureAppUrl}
                footNoteEngineId={footNoteEngineId}
              />
            </Suspense>
          )}
        </div>
      ) : (
        layerContent
      )}
    </OneLayer>
  );
};

// Create a lazy version of FEFA by wrapping it in a Promise.
const LazyFefa = React.lazy(() =>
  // Wrap in Promise.resolve so it looks like a dynamic import.
  Promise.resolve({ default: FEFA })
);

const FEFA: React.FC<{
  layerElementClassName: string;
  fefaFeatureAppUrl: string | undefined;
  oneFootnoteUrls: string[] | undefined;
  footNoteEngineId: string;
}> = ({ layerElementClassName, oneFootnoteUrls, fefaFeatureAppUrl, footNoteEngineId }) => (
  <FeatureAppLoader
    key={footNoteEngineId}
    featureAppId={footNoteEngineId}
    baseUrl={fefaFeatureAppUrl}
    config={{
      uiTheme: 'light',
      apiUrls: oneFootnoteUrls,
      disclaimerManagerScope: 'DEFAULT',
      layerElementClassName,
      __type: 'layer-headless'
    }}
    // @ts-expect-error cannot change type
    src={fefaFeatureAppUrl}
  />
);
export default OneLayerManager;
